// src/Pages/homePage.jsx
import React from 'react';

function Home() {
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h1 className="text-primary">Welcome to MyWebsite</h1>
          <p className="lead">This is the homepage of my React application styled with Bootstrap.</p>
          <button className="btn btn-primary">Learn More</button>
        </div>
      </div>
    </div>
  );
}

export default Home;
