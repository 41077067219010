import React from 'react';
import Header from './Header'; 

function Layout({ children }) {
  return (
    <div className="d-flex flex-column min-vh-100">
      <Header /> {/* Header  */}

      {/* Main Content */}
      <main className="flex-grow-1 container mt-4">{children}</main>

      {/* Footer */}
      <footer className="bg-light text-center py-3 mt-4">
        <p>&copy; 2024 prkshsmn-MyWebsite. All Rights Reserved.</p>
      </footer>
    </div>
  );
}

export default Layout;
