//src/Pages/HevyPage.jsx
import React, { useEffect, useState } from 'react';
import { fetchAllWorkouts } from '../scripts/fetchWorkouts'; 
import GraphComponent from '../Components/GraphComponent'; 
import { Card, Table, Form, FormControl, Spinner } from 'react-bootstrap'; // Import Spinner

function HevyPage() {
  const [workouts, setWorkouts] = useState([]);
  const [mostRecentWorkout, setMostRecentWorkout] = useState(null);
  const [selectedExerciseData, setSelectedExerciseData] = useState([]);
  const [exerciseList, setExerciseList] = useState([]);
  const [selectedExercise, setSelectedExercise] = useState('');
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    async function getAllWorkouts() {
      const data = await fetchAllWorkouts();
      if (data && data.length > 0) {
        setWorkouts(data);
        setMostRecentWorkout(data[0]);
        const exercises = new Set();
        data.forEach(workout => {
          workout.exercises.forEach(exercise => exercises.add(exercise.title));
        });
        setExerciseList([...exercises]);
      }
      setLoading(false); // Set loading to false after fetching data
    }

    getAllWorkouts();
  }, []);

  useEffect(() => {
    if (workouts.length > 0 && selectedExercise) {
      const aggregatedData = workouts
        .map(workout => {
          const matchingExercise = workout.exercises.find(ex => ex.title === selectedExercise);
          if (matchingExercise && matchingExercise.sets.length > 0) {
            return {
              date: new Date(workout.start_time),
              formattedDate: new Date(workout.start_time).toLocaleDateString(),
              weight: matchingExercise.sets[0].weight_kg || 0,
            };
          }
          return null;
        })
        .filter(Boolean)
        .sort((a, b) => a.date - b.date);

      setSelectedExerciseData(aggregatedData);
    }
  }, [workouts, selectedExercise]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="hevy-page">
      <h1 className="mb-4">Most Recent Workout</h1>
      {mostRecentWorkout && mostRecentWorkout.exercises && mostRecentWorkout.exercises.length > 0 ? (
        mostRecentWorkout.exercises.map((exercise, index) => (
          <Card key={index} className="mb-4">
            <Card.Body>
              <Card.Title as="h2">{exercise.title}</Card.Title>
              <Card.Text>{exercise.notes || 'No notes'}</Card.Text>
              <h4>Sets:</h4>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Set #</th>
                    <th>Weight (kg)</th>
                    <th>Reps</th>
                    <th>RPE</th>
                  </tr>
                </thead>
                <tbody>
                  {exercise.sets.map((set, setIndex) => (
                    <tr key={setIndex}>
                      <td>{setIndex + 1}</td>
                      <td>{set.weight_kg ? set.weight_kg.toFixed(2) : 'N/A'} kg</td>
                      <td>{set.reps}</td>
                      <td>{set.rpe || 'N/A'}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {/* Render graph for all-time progress for this exercise */}
              <GraphComponent
                data={workouts
                  .map(workout => {
                    const matchingExercise = workout.exercises.find(ex => ex.title === exercise.title);
                    if (matchingExercise && matchingExercise.sets.length > 0) {
                      return {
                        date: new Date(workout.start_time).toLocaleDateString(),
                        weight: matchingExercise.sets[0].weight_kg || 0,
                      };
                    }
                    return null;
                  })
                  .filter(Boolean)
                  .sort((a, b) => new Date(a.date) - new Date(b.date))}
              />
            </Card.Body>
          </Card>
        ))
      ) : (
        <p>No exercises found for the most recent workout.</p>
      )}

      <div className="exercise-selection">
        <h2>View Progress for Specific Exercises</h2>
        <Form.Group controlId="exercise-select">
          <Form.Label>Select an Exercise:</Form.Label>
          <FormControl
            as="select"
            value={selectedExercise}
            onChange={(e) => setSelectedExercise(e.target.value)}
          >
            <option value="">--Choose an Exercise--</option>
            {exerciseList.map((exercise, index) => (
              <option key={index} value={exercise}>
                {exercise}
              </option>
            ))}
          </FormControl>
        </Form.Group>

        {selectedExercise && selectedExerciseData.length > 0 ? (
          <div>
            <h3>Progress Over Time for "{selectedExercise}"</h3>
            <GraphComponent data={selectedExerciseData} />
          </div>
        ) : (
          <p>Please select an exercise to view the graph.</p>
        )}
      </div>
    </div>
  );
}

export default HevyPage;
