import React from 'react';

function AboutPage() {
  return (
    <div className="container mt-5">
      <h1>
        <a 
          href="/Prakash_Somani_Resume_2024-2.pdf" 
          download 
        >
          About Me
        </a>
      </h1>
      <p className="lead">Welcome to my professional profile page! Here, you'll learn more about my background, skills, and experiences.</p>


      <section className="mb-4">
        <h2>Professional Summary</h2>
        <p>
          I am a driven and experienced Software Engineer specializing in software and process analysis, software generation and repair, 
          and team-based development. My strong critical thinking skills, adaptability, and attention to detail have allowed me to deliver 
          high-quality solutions and drive continuous improvement in my work.
        </p>
      </section>

      <section className="mb-4">
        <h2>Core Competencies</h2>
        <ul>
          <li>Collaboration & Participation</li>
          <li>Effective Critical Thinking Skills</li>
          <li>Oral / Written Communication</li>
          <li>Time Management Skills</li>
          <li>Training & Coordination</li>
        </ul>
      </section>

      <section className="mb-4">
        <h2>Technical Skills</h2>
        <p><strong>Programming Languages & Tools:</strong></p>
        <ul>
          <li>AWS, Linux/Ubuntu, PHP/Laravel, Git/Github</li>
          <li>SCRUM, Bootstrap, React JS, REST API</li>
          <li>CI/CD, Stripe, C++, Java, Node/JavaScript</li>
          <li>Python, MySQL</li>
        </ul>
      </section>

      <section className="mb-4">
        <h2>Selected Employment Highlights</h2>
        <ul>
          <li>
            Launched a new subscription-based service, enabling seamless online ordering, integrating Stripe for secure payments, and automating customer management, leading to increased user engagement and revenue growth.
          </li>
          <li>
            Conducted software validation testing for a Class II medical device, leading to the market release of a new and highly anticipated medical device.
          </li>
          <li>
            Developed and documented an Automated Wheel-Correction prototype for Steering Mode using HydraForce's ECDR ECU, aiding in ECU development.
          </li>
        </ul>
      </section>

      <section className="mb-4">
        <h2>Professional Experience</h2>
        <h3>Painless Analytics | Columbus, OH</h3>
        <p><em>Full Stack Software Developer Co-op</em> - January 2024 – August 2024</p>
        <ul>
          <li>Developed reusable React components and styled pages with SCSS, implementing responsive designs based on Figma prototypes.</li>
          <li>Created and integrated RESTful API endpoints with CSRF protection for secure data retrieval.</li>
          <li>Configured the Orchid Platform for CRUD operations, managing users, roles, and clusters efficiently.</li>
        </ul>
        <p><em>Full Stack Software Developer Co-op</em> - May 2023 – August 2023</p>
        <ul>
          <li>Built log rotation scripts for system maintenance, enhancing reliability and performance monitoring.</li>
          <li>Led the development of analytics pages to provide detailed insights into user traffic and usage patterns.</li>
        </ul>

        <h3>Guerbet | Cincinnati, OH</h3>
        <p><em>D&E Co-op, Software Co-op</em> - August 2022 – December 2022</p>
        <ul>
          <li>Developed expertise in CAN communications for CiA-425 standard implementation in Injection-Scanner communications.</li>
          <li>Supported third-party product integrations, enhancing global sales capabilities.</li>
        </ul>
        <p><em>D&E Co-op, Automation Co-op</em> - January 2022 – April 2022</p>
        <ul>
          <li>Developed internal utilities to improve customer service response times and workflows.</li>
          <li>Researched and implemented solutions for automated hardware testing efforts.</li>
        </ul>

        <h3>HydraForce | Libertyville, IL</h3>
        <p><em>Applications Engineer Intern</em> - May 2021 – August 2021</p>
        <ul>
          <li>Developed and documented an Automated Wheel-Correction prototype for Steering Mode using the ECDR ECU.</li>
        </ul>
      </section>

      <section className="mb-4">
        <h2>Education</h2>
        <p><strong>University of Cincinnati</strong> - B.S. in Computer Engineering, Minor: Computer Science | Expected 2025</p>
        <p>Member of Theta Tau Professional Engineering Fraternity, TEDx Discovering Leadership Program</p>
      </section>
    </div>
  );
}

export default AboutPage;
