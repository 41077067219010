// GraphComponent.jsx
import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const GraphComponent = ({ data }) => {
  const dataInLbs = data.map(entry => ({
    ...entry,
    weight: entry.weight ? entry.weight * 2.20462 : 0, // Convert kg to lbs
  }));

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={dataInLbs}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" label={{ value: 'Date', position: 'insideBottom', offset: -5 }} />
        <YAxis label={{ value: 'Weight (lbs)', angle: -90, position: 'insideLeft' }} />
        <Tooltip />
        <Legend content={() => null} /> {/* This hides the legend */}
        <Line type="monotone" dataKey="weight" stroke="#8884d8" name="Weight (lbs)" />
      </LineChart>
    </ResponsiveContainer>
  );
};


export default GraphComponent;
