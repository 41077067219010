import React from 'react';

function ContactPage() {
  return (
    <div className="container mt-5">
      <h1>Contact Me</h1>
      <p className="lead">I'd love to hear from you! You can reach out to me using the information below.</p>

      <section className="mb-4">
        <h2>Contact Information</h2>
        <ul className="list-unstyled">
          <li>
            <strong>Email:</strong> <a href="mailto:prkshsmn@gmail.com">prkshsmn@gmail.com</a>
          </li>
          <li>
            <strong>Phone:</strong> <a href="tel:+18473091509">847-309-1509</a>
          </li>
          <li>
            <strong>Location:</strong> Cincinnati, OH 45219
          </li>
          <li>
            <strong>LinkedIn:</strong> <a href="https://www.linkedin.com/in/prakash-somani-8971a21b0/" target="_blank" rel="noopener noreferrer">Prakash Somani</a>
          </li>
        </ul>
      </section>

      <section className="mb-4">
        <h2>Send Me a Message</h2>
        <form>
          <div className="mb-3">
            <label htmlFor="name" className="form-label">Name</label>
            <input type="text" className="form-control" id="name" placeholder="Your Name" />
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">Email</label>
            <input type="email" className="form-control" id="email" placeholder="Your Email" />
          </div>
          <div className="mb-3">
            <label htmlFor="message" className="form-label">Message</label>
            <textarea className="form-control" id="message" rows="5" placeholder="Your Message"></textarea>
          </div>
          <button type="submit" className="btn btn-primary">Submit</button>
        </form>
      </section>
    </div>
  );
}

export default ContactPage;
