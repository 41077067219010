import React from 'react';
import { Link } from 'react-router-dom';

function ProjectPage() {
  return (
    <div className="project-page">
      {/* Page Title */}
      <h1 className="mb-4">Projects</h1>

      {/* Project List Section */}
      {/* Project 1: Painless Analytics */}
      <div className="row">
        <div className="col-md-4 mb-4">
          <div className="card h-100">
            <div className="card-body">
              <h5 className="card-title">Painless Analytics</h5>
              <hr style={{ borderColor: 'gray', margin: '0.5rem 0' }} />
              <p className="card-text">
              Launched a new subscription-based service, enabling seamless online ordering, integrating Stripe for secure payments, and 
              automating customer management, leading to increased user engagement and revenue growth 
              </p>
            </div>
            <div className="card-footer">
              <a href="Painless Analytics" className="btn btn-primary">Link to Website</a>
            </div>
          </div>
        </div>


        {/* Example Project 2 */}
        <div className="col-md-4 mb-4">
          <div className="card h-100">
            <div className="card-body">
              <h5 className="card-title">Project Title 2</h5>
              <hr style={{ borderColor: 'gray', margin: '0.5rem 0' }} />
              <p className="card-text">
                Brief description of the project, including key features or technologies used.
              </p>
            </div>
            <div className="card-footer">
                <Link to="/hevy" className="btn btn-primary">Learn More</Link>
            </div>
          </div>
        </div>

    
      </div>
    </div>
  );
}

export default ProjectPage;
